<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getDataReport"
  :isLoading="isLoading"
  :radioListItem="radioButtonReportISC"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { radioButtonReportISC } from '@/configs/constants'

export default {
  data () {
    return {
      headers: [
        { value: 'id',
          text: this.$t('tableHeaders.id_statement'),
          sortable: false },
        { value: 'sailor_key',
          text: this.$t('tableHeaders.sailor_id'),
          sortable: false },
        { value: 'sailor_full_name',
          text: this.$t('tableHeaders.sailor_full_name'),
          sortable: false },
        { value: 'crewing',
          text: this.$t('tableHeaders.crewing'),
          sortable: false },
        { value: 'manager',
          text: this.$t('tableHeaders.manager'),
          sortable: false },
        { value: 'manager_full_name',
          text: this.$t('tableHeaders.manager_full_name'),
          sortable: false },
        { value: 'date_end_proxy',
          text: this.$t('tableHeaders.date_end_proxy'),
          sortable: false },
        { value: 'created_at',
          text: this.$t('tableHeaders.created_at'),
          sortable: false },
        { value: 'status_document',
          text: this.$t('tableHeaders.status_document'),
          sortable: false }
        // { value: 'event',
        //   text: this.$t('go'),
        //   sortable: false }
      ],
      actions: [
        { id: 1, tooltip: 'tooltip.go', action: (item) => this.goToDocumentCadet(item), color: 'blue', name: 'mdi-arrow-right', isView: true }
      ],
      radioButtonReportISC
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.connectISC,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getConnectStatementISO']),
    async getDataReport (filter) {
      await this.getConnectStatementISO(filter)
    }
  }
}
</script>
